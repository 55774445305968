import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'

class SideDrawer extends Component {
  render() {
    return (
      <div className={this.props.show ? 'sideDrawer open' : 'sideDrawer'}>
        <div className='close-button-container'>
          <button onClick={this.props.open} className='btn-close'>
            <img src={this.props.closeButton} alt='Close Icon' />
          </button>
        </div>
        <ul className='menu-container'>
          {this.props.title ? (
            <li className='menu-heading'>
              <h1>{this.props.title}</h1>
            </li>
          ) : (
            ''
          )}

          {this.props.sideDrawerItems.map((item, index) => (
            <li className='menu-item' key={`${index}`}>
              <NavLink to={item.link} exact={true}>
                <p>{item.title}</p>
              </NavLink>
            </li>
          ))}
        </ul>
        {this.props.contact ? (
          <div className='phone-container'>
            <img src={this.props.phoneIcon} alt='contact us' />
            <p>{this.props.contact}</p>
          </div>
        ) : (
          ''
        )}
        {this.props.quoteBtn ? (
          <div className='buttons-container'>{this.props.quoteBtn}</div>
        ) : (
          ''
        )}
        {this.props.socials ? (
          <div className='socials-container'>
            {this.props.socials.map((social, index) => (
              <a href={social.link}>
                <img src={social.image} key={`${index}`} alt='socials media icon' />
              </a>
            ))}
          </div>
        ) : (
          ''
        )}
      </div>
    )
  }
}
export default SideDrawer
