import React, { Component } from 'react'
import { DgPicture } from 'dg-utils'
import { Link } from 'react-router-dom';

// Styles
import './card.scss';

// Actions
import { selectPost } from '../actions/post.actions';

// Assets
import news_1 from '../assets/news-1.jpg'

class CardAltSmall extends Component {

  constructor(props) {
    super(props);

    this.onSelect = this.onSelect.bind(this);
  }

  onSelect(post) {
    selectPost(post);
    window.scrollTo(0,0);
  }

  render() {
    return (
      <div
        style={this.props.style}
        className={
          this.props.cardClass
            ? `blog-card ${this.props.cardClass}`
            : 'blog-card'
        }
      >
        <Link
          to={`${this.props.urlPrefix ? this.props.urlPrefix : '/blog/post'}/${this.props.post._id}`}
          className="blog-link-wrapper"
          onClick={() => this.onSelect(this.props.post)}
        >
          <div className='img'>
            <DgPicture
              className={
                this.props.imageContainerClass
                  ? this.props.imageContainerClass
                  : ''
              }
            >
              <img
                src={
                  this.props.post &&
                  this.props.post.header_image &&
                  this.props.post.header_image !== ''
                    ? this.props.post.header_image
                    : news_1
                }
                alt='post header img'
                loading='lazy'
              />
            </DgPicture>

            <h4>{this.props.post.title}</h4>
            {this.props.imageTint ? (
              <div className={this.props.imageTint}></div>
            ) : (
              ''
            )}
          </div>
          <div className='blog-card-body'>
            <h5>{this.props.post.summary}</h5>
            {this.props.linkText ? this.props.linkText : ''}
          </div>
        </Link>
      </div>
    )
  }
}

export default CardAltSmall
