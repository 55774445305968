import React from 'react';
import '../form.scss';

export const inputSelect = (field) => (
    <div className={field.containerClass}>
        {field.label ? <label className={field.labelClass ? field.labelClass : ''}>{field.label}</label>: ''}
        <input 
            {...field.input} 
            placeholder={field.placeholder} 
            className={field.inputClass} 
            type={field.type} 
            disabled={field.disabled} 
            autoComplete={field.autoComplete} 
            list="dg-input-select"
        />
        <datalist id="dg-input-select">
            {field.children}
        </datalist>
        <span className="form-error">{(field.meta.touched && field.meta.error) || (field.meta.asyncValidating) ? field.meta.error : ''}</span>
    </div>
)