import React, { Component } from 'react'
import { Router } from 'react-router-dom'

//Components
import SideDrawer from './sideDrawer.component'

class Navbar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      sideDrawerOpen: false
    }
    this.drawerToggleClickHandler = this.drawerToggleClickHandler.bind(this)
  }

  drawerToggleClickHandler() {
    this.setState((prevState) => {
      return { sideDrawerOpen: !prevState.sideDrawerOpen }
    })
  }

  render() {
    return (
      <div className='nav'>
        <div className='navbar-header'>
          <div className='mobile-logo'>
            <img src={this.props.logoAlt} alt='mobile logo' />
          </div>
          <div className='hamburger-icon'>
            <button onClick={this.drawerToggleClickHandler}>
              <img src={this.props.menuIcon} alt='Menu Icon' />
            </button>
          </div>
        </div>
        <Router history={this.props.history}>
          <SideDrawer
            button={this.props.button}
            open={this.drawerToggleClickHandler}
            show={this.state.sideDrawerOpen}
            closeButton={this.props.closeIcon}
            title={this.props.title}
            socials={this.props.socials}
            quoteBtn={this.props.quoteBtn}
            phoneIcon={this.props.phoneIcon}
            sideDrawerItems={this.props.sideDrawerItems}
          />
        </Router>
      </div>
    )
  }
}

export default Navbar
