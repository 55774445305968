import React, { Component } from 'react';
import './featurePost.scss';

// Assets
import news_1 from '../assets/news-1.jpg';

class FeaturedPost extends Component {

    render() {

        return (
            <div style={this.props.style} className={this.props.cardClass? `feature-post ${this.props.cardClass}`:"feature-post"}>
                <div className="img">
                    <img src={
                            this.props.post &&
                            this.props.post.header_image &&
                            this.props.post.header_image !== '' ? 
                            this.props.post.header_image : news_1} alt="Feature Img" />
                </div>
                <div className="content">
                    <div className="tag-date-info">
                        {
                            this.props.post &&
                            this.props.post.tag &&
                            this.props.post.tag != '' ? 
                            (
                                <div className="item">
                                    <span className="badge">{this.props.post.tag}</span>
                                </div>
                            ) : ''
                        }
                        <div className="item">
                            <h6>{new Date(this.props.post.createdAt).toLocaleDateString('en-uk', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}</h6>
                        </div>
                    </div>
                    <h2 className="text-capitalize">{this.props.post.title}</h2>
                    <p>{this.props.post.summary}</p>
                    <div>
                        <button 
                            type="button" 
                            className="redux-blogs-btn-primary"
                            onClick={() => this.props.onSelect(this.props.post)}
                        >
                            Read More
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

export default FeaturedPost;