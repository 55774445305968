import React, { Component } from 'react'
import './filters.scss'

class BlogFilters extends Component {
  constructor(props) {
    super(props)

    this.onFilter = this.onFilter.bind(this)
    this.onSelect = this.onSelect.bind(this)
  }

  onFilter(filter) {
    console.log(`Filter Posts - ${filter}`)
    this.props.setFilter(filter)

    this.props.fetchPosts('', '', filter)
  }

  onSelect(e) {
    console.log(`Filter Posts - ${e.target.value}`)
    const topic = e.target.value
    this.onFilter(topic)
  }

  render() {
    let filterList = []
    let filterOptions = []

    if (this.props.filters && this.props.filters.length > 0) {
      filterList = this.props.filters.map((filter, index) => (
        <li
          key={`filter_list_${index}`}
          className={this.props.filter === filter.value ? 'active' : ''}
          onClick={() => this.onFilter(filter.value)}
        >
          {filter.displayValue}
        </li>
      ))

      filterOptions = this.props.filters.map((filter, index) => (
        <option key={`filter_option_${index}`} value={filter.value}>
          {filter.displayValue}
        </option>
      ))
    }

    return (
      <div className='filters'>
        <ul className="drop-dwn">
          <li
            className={this.props.filter === '' ? 'active' : ''}
            onClick={() => this.onFilter('')}
          >
            All Posts
          </li>
          {filterList}
        </ul>
        <select className="select-drop-dwn"onChange={this.onSelect} defaultValue={``}>
          <option value={``}>All Posts</option>
          {filterOptions}
        </select>
      </div>
    )
  }
}

export default BlogFilters
