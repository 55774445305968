import React, { Component } from 'react'
import './filters.scss'

class BlogFilters extends Component {

  constructor(props) {
    super(props);

    this.onFilter = this.onFilter.bind(this);
    this.onSelect = this.onSelect.bind(this);
  }

  onFilter(filter) {
    console.log(`Filter Posts - ${filter} (onFilter)`);
    this.props.setFilter(filter);
    this.props.fetchPosts('', '', filter);
  }

  onSelect(e) {
    console.log(`Filter Posts - ${e.target.value} (onSelect)`);
    const topic = e.target.value;
    this.onFilter(topic);
  }

  render() {
    let filterList = [];
    let filterOptions = [];

    if (this.props.filters && this.props.filters.length > 0) {
      filterList = this.props.filters.map((filter, index) => (
        <li
          key={`filter_list_${index}`}
          className={this.props.filter === filter.value ? 'active' : ''}
          onClick={() => this.onFilter(filter.value)}
        >
          {filter.displayValue}
        </li>
      ))

      filterOptions = this.props.filters.map((filter, index) => (
        <option key={`filter_option_${index}`} value={filter.value}>
          {filter.displayValue}
        </option>
      ))
    }

    return (
      <div className='filters'>
          {/* <input
          type='text'
          className='text-main'
          placeholder='SEARCH'
          onChange={this.handleChange}
        />*/}
        <select
          className='select-main'
          onChange={this.onSelect}
          name='category'
          defaultValue={''}
        >
          <option value={``}>CATEGORY</option>
          {filterOptions}
        </select>
        {/*<select className='select-main' onChange={this.onSelect} name='recent'>
          <option value={``}>RECENT</option>
        </select>*/}
      </div>
    )
  }
}

export default BlogFilters
