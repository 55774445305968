import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { dgSelect } from 'dg-forms'

// Styles
import '../filtersNew.scss'

let Select_form = (props) => {
  const { handleSubmit, onSubmit, label, submitting, tags } = props

  let tagOptions = [];
  if (tags && tags.length > 0) {
    tagOptions = tags.map((tag, index) => (
      <option key={`tag_option_${index}`} value={tag}>
        {tag}
      </option>
    ))
  }

  return (
    <form className='filterSelect-form' onSubmit={handleSubmit(onSubmit)}>
      <Field
        name='tag'
        type='text'
        component={dgSelect}
        containerClass='field-group filter-select'
        label={label ? label : ""}
      >
        <option value=''>All Posts</option>
        {tagOptions}
      </Field>
      <button type='submit' disabled={submitting} className='btn-primary'>
        Filter
      </button>
    </form>
  )
}

Select_form = reduxForm({
  form: 'Dg_Blogs_Select_form'
})(Select_form)

export const Selectform = Select_form
