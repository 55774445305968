import React, { Component } from 'react'
import Swal from 'sweetalert2'

// Styles
import './filtersNew.scss'

// Components
import { Selectform } from './forms/filtersSelect.form'

class FiltersNew extends Component {
  constructor(props) {
    super(props)

    this.onSubmit = this.onSubmit.bind(this)
    this.getFilterDisplay = this.getFilterDisplay.bind(this)
  }

  componentDidMount() {
    this.props.getAllTags()
  }

  onSubmit(data) {
    let query = this.props.post.filter || {}
    let page = this.props.post.page || 0
    let page_size = this.props.post.page_size || 6

    console.log(data)
    console.log(this.props.post)
    if (data && data.tag && data.tag !== undefined) {
      query.tag = data.tag
      this.props.setFilter(query)
    }

    if (data.tag === '' || data.tag === ' ' || data.tag === undefined) {
      delete query.tag
      this.props.setFilter(query)
    }

    console.log('query: ', query)
    this.props.fetchPosts(page_size, 0, query).catch((res) => {
      Swal.fire('Error', res.message, 'error')
    })
  }

  getFilterDisplay(displayType) {
    let type = displayType.toUpperCase()
    switch (type) {
      case 'DROPDOWN':
        return (
          <Selectform
            onSubmit={this.onSubmit}
            tags={this.props.tags}
            label={this.props.label}
              enableReinitialize={true}
          />
        )
      case 'LIST':
        let tagOptions = []
        if (this.props.tags && this.props.tags.length > 0) {
          tagOptions = this.props.tags.map((tag, index) => (
              <div
                key={`option_${index}`}
              >
              <li
                key={`tag_option_${index}`}
                onClick={() => this.onSubmit({ tag: tag })}
                className={
                  this.props.filter && this.props.filter.tag === tag
                    ? 'active'
                    : ''
                }
                value={tag}
                // className={
                //   Object.values(this.props.filter).indexOf(tag) > -1
                //     ? 'active'
                //     : ''
                // }
              >
                {tag}
              </li>
            </div>
          ))
        }
        return (
          <div className='tag-container'>
            <div className='tag-wrapper-desktop'>
              <div className='tags'>
                <ul>
                  <li
                    className={
                      this.props.filter && (this.props.filter.tag === undefined || this.props.filter.tag === '')
                        ? 'active'
                        : ''
                    }
                    onClick={() => this.onSubmit({ tag: '' })}
                  >
                    All Posts
                  </li>
                  {tagOptions}
                </ul>
              </div>
            </div>
            <div className='tag-wrapper-mobile'>
              <Selectform
                onSubmit={this.onSubmit}
                tags={this.props.tags}
                enableReinitialize={true}
              />
            </div>
          </div>
        )
      default:
        return (
          <Selectform
            onSubmit={this.onSubmit}
            tags={this.props.tags}
            enableReinitialize={true}
          />
        )
    }
  }
  render() {
    return (
      <div className='filters'>
        {this.getFilterDisplay(this.props.filterDisplay)}
      </div>
    )
  }
}

export default FiltersNew
