import React, { Component } from 'react';
import './blogEditor.scss';

// Actions
// import { createNewPost, setClearPostForm } from '../../../actions/post.action';
import { getAllTags, uploadImage } from '../../actions/post.actions';

// Components
import { BlogForm } from './blogForm.component';

class NewPostPage extends Component {

    componentDidMount() {
        getAllTags();
    }
    
    render() {
        return (
            <div className='blogEditor'>
                {
                    this.props.post &&
                    this.props.post.title !== '' ? (
                        <BlogForm 
                            handleSelectedFile={this.props.handleSelectedFile}
                            onSubmit={this.props.onSubmit}
                            uploadImage={this.props.uploadImage}
                            blog={this.props.blog}
                            tags={this.props.post?.tags}
                            headerImageAccept={this.props.headerImageAccept}
                        />
                    ) : (
                        this.props.noPostComponent ? this.props.noPostComponent : (
                            <h1>No Post Selected</h1>
                        )
                    )
                }
            </div>
        );
    }
}

export default NewPostPage;
