import React from 'react'
import '../form.scss'
import defaultTipIcon from '../defaultIcon.svg'
export const input = ({
  input,
  label,
  preventPastDate,
  preventFutureDate,
  containerClass,
  inputSymbol,
  inputContainerClass,
  labelClass,
  placeholder,
  toolTipText,
  tipIcon,
  inputClass,
  defaultChecked,
  type,
  disabled,
  autoComplete,
  meta: { asyncValidating, touched, error, warning }
}) => (
  <div className={containerClass ? containerClass : 'field-group'}>
    {toolTipText ? (
      <div className='toolTip'>
        <label className={labelClass}>{label}</label>
        <img
          src={tipIcon ? tipIcon : defaultTipIcon}
          className='toolTip-image'
          alt='tool tip'
        />
        <span className='toolTipText'>{toolTipText}</span>
      </div>
    ) : (
      <label className={labelClass}>{label}</label>
    )}
    {/* Fields with symbol inside input container - '€' */}
    {inputSymbol ? (
      <div
        className={
          inputContainerClass ? inputContainerClass : 'form-input-container'
        }
      >
        <div className='input-group'>
          <div className='input-group-text'>{inputSymbol}</div>
          <input
            {...input}
            placeholder={placeholder ? placeholder : label}
            className={inputClass}
            type={type}
            disabled={disabled}
            autoComplete={autoComplete}
          />
        </div>
        <span className='form-error'>
          {(touched && error) || asyncValidating ? error : ''}
        </span>
      </div>
    ) : (
      <div
        className={
          inputContainerClass ? inputContainerClass : 'form-input-container'
        }
      >
        <input
          {...input}
          placeholder={placeholder ? placeholder : label}
          className={inputClass}
          type={type}
          min={preventPastDate ? new Date().toISOString().split('T')[0] : ''}
          max={preventFutureDate ? new Date().toISOString().split('T')[0] : ''}
          disabled={disabled}
          autoComplete={autoComplete}
        />
        <span className='form-error'>
          {(touched && error) || asyncValidating ? error : ''}
        </span>
      </div>
    )}
  </div>
)
