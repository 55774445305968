import {
  SET_IS_POST_LOADING,
  FETCH_POSTS,
  SELECT_POST,
  SET_FILTER,
  SEARCH_SUCCESS,
  SEARCH_FAILED,
  FETCH_POST,
  IMAGE_UPLOAD,
  GET_ALL_TAGS
} from './actions/types'

const initialState = {
  page_size: 1,
  page: 0,
  total_pages: 1,
  total_items: 1,
  filter: {},
  items: [],
  item: {},
  tags: [],
  currentPost: {},
  clearForm: true,
  ok: true,
  message: '',
  image: null,
  errors: [],
  isPostLoading: false
}

export default function (state = initialState, action) {
  switch (action.type) {
    case SELECT_POST:
      return {
        ...state,
        currentPost: action.payload
      }
    case SET_IS_POST_LOADING:
      return {
        ...state,
        isPostLoading: action.payload
      }
    case SET_FILTER:
      return {
        ...state,
        filter: action.payload
      }

    case FETCH_POST:
      return {
        ...state,
        currentPost: action.payload.post,
        ok: action.payload.ok,
        errors: action.payload.errors,
        message: action.payload.message,
        isPostLoading: false
      }
    case FETCH_POSTS:
      return {
        ...state,
        items: action.payload.data,
        page_size: action.payload.page_size,
        page: action.payload.page,
        total_pages: action.payload.total_pages,
        total_items: action.payload.total_items,
        ok: action.payload.ok,
        message: action.payload.message,
        errors: action.payload.errors
      }
    case SEARCH_SUCCESS:
      return {
        ...state,
        ok: action.payload.ok,
        items: action.payload.data,
        message: action.payload.message
      }
    case SEARCH_FAILED:
      return {
        ...state,
        ok: action.payload.ok,
        errors: action.payload.errors,
        message: action.payload.message
      }
    case GET_ALL_TAGS:
      return {
        ...state,
        ok: action.payload.ok,
        errors: action.payload.errors,
        message: action.payload.message,
        tags: action.payload.tags
      }
    case IMAGE_UPLOAD:
      return {
        ...state,
        ok: action.payload.ok,
        errors: action.payload.errors,
        message: action.payload.message,
        image: action.payload.image
      }
    default:
      return state
  }
}
