import {
  SET_IS_POST_LOADING,
  FETCH_POSTS,
  SELECT_POST,
  SET_FILTER,
  FETCH_POST,
  IMAGE_UPLOAD,
  SEARCH_FAILED,
  SEARCH_SUCCESS,
  GET_ALL_TAGS
} from './types'
import { reset } from 'redux-form'

export const setPostLoading = (condition) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: SET_IS_POST_LOADING,
      payload: condition
    })
  })
}
export const getPostById = (id) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    console.log('getPostById')
    // Show spinner
    dispatch({
      type: SET_IS_POST_LOADING,
      payload: true
    })

    fetch(`${process.env.REACT_APP_API_URL}/api/v1/blog/get/${id}`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json'
      }
    })
      .then((res) => res.json())
      .then((post) => {
        if (!post.ok) {
          dispatch({
            type: FETCH_POST,
            payload: post
          })

          reject(post)
        } else {
          dispatch({
            type: FETCH_POST,
            payload: post
          })

          resolve(post)
        }
      })
  })
}

export const fetchPosts = (pageSize, pageNo, queryObject) => (dispatch) => {
  return new Promise((resolve, reject) => {
    // Show spinner
    dispatch({
      type: SET_IS_POST_LOADING,
      payload: true
    })

    let query = ``

    if (Object.keys(queryObject).length > 0) {
      for (const key in queryObject) {
        if (Object.hasOwnProperty.call(queryObject, key)) {
          const element = queryObject[key]
          query = `${query}&${key}=${element}`
        }
      }
    }

    fetch(
      `${process.env.REACT_APP_API_URL}/api/v1/blog/?page_size=${pageSize}&page=${pageNo}${query}`
    )
      .then((res) => res.json())
      .then((posts) => {
        dispatch({
          type: SET_IS_POST_LOADING,
          payload: false
        })

        if (!posts.ok) {
          dispatch({
            type: FETCH_POSTS,
            payload: posts
          })

          reject(posts)
        } else {
          dispatch({
            type: FETCH_POSTS,
            payload: posts
          })

          resolve(posts)
        }
      })
  })
}

export const selectPost = (postData) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: SELECT_POST,
      payload: postData
    })

    resolve(postData)
  })
}

export const setFilter = (filter) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: SET_FILTER,
      payload: filter
    })

    resolve(filter)
  })
}

export const createSearch = (page, page_size, keyword) => (dispatch) => {
  return new Promise((resolve, reject) => {
    fetch(
      `${process.env.REACT_APP_API_URL}/api/v1/blog/search?${
        page_size && page_size !== '' ? `page_size=${page_size}` : ''
      }${page && page !== '' ? `&page=${page}` : ''}&title=${keyword}`,
      {
        method: 'GET',
        headers: {
          'content-type': 'application/json'
        }
      }
    )
      .then((res) => res.json())
      .then((post) => {
        if (!post.ok) {
          dispatch({
            type: SEARCH_FAILED,
            payload: post
          })
          reject(post)
        } else {
          dispatch({
            type: SEARCH_SUCCESS,
            payload: post
          })

          resolve(post)
        }
      })
  })
}

export const getAllTags = () => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    fetch(`${process.env.REACT_APP_API_URL}/api/v1/blog/getFilterOptions`)
      .then((res) => res.json())
      .then((post) => {
        dispatch({
          type: GET_ALL_TAGS,
          payload: post
        })

        if (!post.ok) {
          reject(post)
        } else {
          resolve(post)
        }
      })
  })
}

export const uploadImage = (data) => (dispatch) => {
    return new Promise((resolve, reject) => {

    fetch(`${process.env.REACT_APP_API_URL}/api/v1/blog/image-upload`, {
      method: 'POST',
      body: data
    })
      .then((res) => res.json())
      .then((post) => {
        dispatch({
          type: IMAGE_UPLOAD,
          payload: post
        })

        if (!post.ok) {
          reject(post)
        } else {
          resolve(post)
        }
      })
  })
}
