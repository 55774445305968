import React from 'react'
import '../form.scss'

export const textarea = (field) => (
  <div className={field.containerClass ? field.containerClass : 'field-group'} style={field.groupStyle ? field.groupStyle : {}}>
    <label className={field.labelClass}>{field.label}</label>
    <div className='form-input-container'>
      <textarea
        {...field.input}
        disabled={field.disabled}
        placeholder={field.placeholder}
        rows={field.rows || 4}
      />
      <span className='form-error'>
        {field.meta.touched && field.meta.error ? field.meta.error : ''}
      </span>
    </div>
  </div>
)
