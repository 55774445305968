import React, { Component } from 'react';
import './postList.scss';

class PostList extends Component {

    componentWillReceiveProps(nextProps) {
        if(nextProps.newPost) {
            this.props.posts.unshift(nextProps.newPost);
        }
    }

    render() {
        let postItems = [];
        
        if(
            this.props.posts &&
            this.props.posts.length > 0
        ) {
            postItems = this.props.posts.map(post => (
                <div className="card mb-4 shadow-sm" key={`admin_post_list_${post._id}`}>
                    <div className="card-body">
                            <button className="btn-link" onClick={() => this.props.onSelect(post)} >
                                {post.title}
                            </button>
                        <h4 className="text-muted">
                            By { 
                                post.created_by && 
                                post.created_by.displayName &&
                                post.created_by.displayName !== '' ? post.created_by.displayName : ''} - {new Date(post.createdAt).toLocaleDateString('en-uk', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}
                        </h4>
                        <div className="card-text" dangerouslySetInnerHTML={{__html: post.wysiwyg}}></div>
                    </div>
                </div>
            ));
        }

        return (
            <div className="PostList-Admin">
                {this.props.headerComponent ? this.props.headerComponent : (<h1>Posts</h1>)}
                {postItems}
            </div>
        )
    }
}

export default PostList;
