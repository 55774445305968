import React, { Component } from 'react'

// Styles
import './blogs.scss'

// Components

// Featured Posts
import FeaturedPost from '../featurePost/featurePost.component'

// Cards
import Card from '../card/card.component'
import CardAlt from '../card/cardAlt.component'
import CardAltSmall from '../card/cardAltSmall.component'
import CardHorizontal from '../card/cardHorizontal.component'

// Pagination
import Pagination from '../pagination/pagination.component'

// Filters
import Filters from '../filters/filters.component'
import FiltersAlt from '../filters/filtersAlt.component'
import FiltersNew from '../filters/filtersNew'

class Blogs extends Component {
  constructor(props) {
    super(props)

    this.scrollRef = React.createRef()
    this.getFilter = this.getFilter.bind(this)
  }

  getFilter(filterType) {
    let type = filterType.toUpperCase()
    switch (type) {
      case 'ALT':
        return (
          <FiltersAlt
            filters={this.props.filters}
            filter={this.props.filter}
            setFilter={this.props.setFilter}
            fetchPosts={this.props.fetchPosts}
            createSearch={this.props.createSearch}
          />
        )
      case 'NEW':
        return (
          <FiltersNew
            getAllTags={this.props.getAllTags}
            filterDisplay={this.props.filterDisplay}
            tags={this.props.tags}
            label={this.props.filterLabel}
            filter={this.props.filter}
            post={this.props.post}
            setFilter={this.props.setFilter}
            fetchPosts={this.props.fetchPosts}
          />
        )
      default:
        return (
          <Filters
            filters={this.props.filters}
            filter={this.props.filter}
            setFilter={this.props.setFilter}
            fetchPosts={this.props.fetchPosts}
          />
        )
    }
  }

  getCards(cardType, index, post) {
    switch (cardType) {
      case 'horizontal':
      case 'Horizontal':
      case 'HORIZONTAL':
        return (
          <CardHorizontal
            key={`Blog_Card_${index}`}
            post={post}
            style={this.props.postStyle || {}}
            cardClass={this.props.postClass || ''}
            onSelect={
              this.props.postOnSelect ? this.props.postOnSelect : (post) => {}
            }
          />
        )
      case 'alt':
      case 'Alt':
      case 'ALT':
        return (
          <CardAlt
            key={`Blog_Card_${index}`}
            post={post}
            style={this.props.postStyle || {}}
            showDate={this.props.showDate}
            showSummary={this.props.showSummary}
            cardClass={this.props.postClass || ''}
            onSelect={
              this.props.postOnSelect ? this.props.postOnSelect : (post) => {}
            }
          />
        )
      case 'small':
      case 'Small':
      case 'SMALL':
        return (
          <CardAltSmall
            key={`Blog_Card_${index}`}
            post={post}
            style={this.props.postStyle || {}}
            linkText={this.props.linkText}
            imageTint={this.props.imageTint}
            cardClass={this.props.postClass || ''}
            onSelect={
              this.props.postOnSelect ? this.props.postOnSelect : (post) => {}
            }
          />
        )
      case 'default':
      case 'Default':
      case 'DEFAULT':
        return (
          <Card
            key={`Blog_Card_${index}`}
            post={post}
            style={this.props.postStyle || {}}
            cardClass={this.props.postClass || ''}
            onSelect={
              this.props.postOnSelect ? this.props.postOnSelect : (post) => {}
            }
          />
        )
      default:
        return (
          <Card
            key={`Blog_Card_${index}`}
            post={post}
            style={this.props.postStyle || {}}
            cardClass={this.props.postClass || ''}
            onSelect={
              this.props.postOnSelect ? this.props.postOnSelect : (post) => {}
            }
          />
        )
    }
  }

  render() {
    let posts = []

    if (this.props.posts && this.props.posts.length > 0) {
      posts = this.props.posts.map((post, index) => (
          <div
              key={`post__${index}`}
          className={
            this.props.containerMiddleClass
              ? this.props.containerMiddleClass
              : ''
          }
        >
          {this.getCards(this.props.cardType, index, post)}
        </div>
      ))
    }

    return (
      <div
        style={this.props.containerStyle ? this.props.containerStyle : {}}
        className={
          this.props.containerClass
            ? `react-blogs-container ${this.props.containerClass}`
            : `react-blogs-container`
        }
      >
        {/* Featured Posts */}
        {this.props.displayFeaturedPost ? (
          <FeaturedPost
            post={this.props.featuredPost}
            style={this.props.featuredPostStyle || {}}
            cardClass={this.props.featuredPostClass || ''}
            onSelect={
              this.props.featuredPostOnSelect
                ? this.props.featuredPostOnSelect
                : () => {}
            }
          />
        ) : (
          ''
        )}

        {/* Filters */}
        {this.props.filterType ? this.getFilter(this.props.filterType) : ''}

        {/* Blog Posts */}
        {this.props.post &&
        this.props.post.items &&
        this.props.post.items.length > 0 ? (
          <div
            ref={this.scrollRef}
            style={this.props.postsContainerStyle}
            className={
              this.props.postsContainerClass
                ? `posts-container ${this.props.postsContainerClass}`
                : `posts-container`
            }
          >
            {posts}
          </div>
        ) : (
          <div
            style={this.props.postsContainerStyle}
            className={
              this.props.postsContainerClass
                ? `posts-container ${this.props.postsContainerClass}`
                : `posts-container`
            }
          >
            <div className='no-posts-container'>
              <h2>We're sorry</h2>
              <p>
                There doesn't seem to be any blog posts available at the moment
              </p>
            </div>
          </div>
        )}

        {/* Pagination */}
        {this.props.posts && this.props.posts.length > 0 ? (
          <Pagination
            paginationContainerStyle={{}}
            paginationContainerClass={''}
            paginationStyle={{}}
            paginationClass={''}
            filter={this.props.filter}
            fetchPosts={this.props.fetchPosts}
            scrollRef={this.scrollRef}
            post={this.props.post}
            total_pages={this.props.total_pages}
            page={this.props.page}
          />
        ) : (
          ''
        )}
      </div>
    )
  }
}

export default Blogs
