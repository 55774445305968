import React, { Component } from 'react';
import Fade from 'react-reveal/Fade';
import { Link } from 'react-router-dom';

// Styles
import './card.scss';

// Actions
import { selectPost } from '../actions/post.actions';

// Assets
import news_1 from '../assets/news-1.jpg'


class Card extends Component {

  constructor(props) {
    super(props);

    this.onSelect = this.onSelect.bind(this);
  }

  onSelect(post) {
    selectPost(post);
    window.scrollTo(0,0);
  }

  render() {
    return (
      <div
        style={this.props.style}
        className={
          this.props.cardClass
            ? `blog-card ${this.props.cardClass}`
            : 'blog-card'
        }
      >
        <Link
          to={`${this.props.urlPrefix ? this.props.urlPrefix : '/blog/post'}/${this.props.post._id}`}
          className="blog-link-wrapper"
          onClick={() => this.onSelect(this.props.post)}
        >
        <Fade>
          <div className='img'>
            <img
              src={
                this.props.post &&
                this.props.post.header_image &&
                this.props.post.header_image !== ''
                  ? this.props.post.header_image
                  : news_1
              }
              alt='post header img'
            />
          </div>
          <div className='content'>
            <h4>{this.props.post.title}</h4>
            <p>{this.props.post.summary}</p>
          </div>
          <div className='footer'>
            <div className='item'>
              <h5>
                {new Date(this.props.post.createdAt).toLocaleDateString(
                  'en-uk',
                  {
                    weekday: 'long',
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric'
                  }
                )}
              </h5>
            </div>
            {this.props.post &&
            this.props.post.tag &&
            this.props.post.tag != '' ? (
              <div className='item text-right'>
                <span className='badge'>{this.props.post.tag}</span>
              </div>
            ) : (
              ''
            )}
          </div>
        </Fade>
        </Link>
      </div>
    )
  }
}

export default Card
