import React, { Component } from 'react';
import './blogPost.scss';
import parse from 'html-react-parser';

class BlogPost extends Component {

    componentDidMount() {
        let urlParams = window.location.pathname;
        let params = urlParams.split('/');

        console.log(`BlogPost - ${params[3]}`);
        console.log(params);
        
        if(
            (
                Object.keys(this.props.post).length === 0 
            ) &&
            params.length >= 4
        ) {
            this.props.getPostById(params[3])
            .then(data => {

            })
            .catch(data => {
                console.log(data);
            })
        }
    }

    render() {
        return (
            <div>
                {
                    this.props.post && 
                    this.props.post.title &&
                    this.props.post.title !== '' ? (
                        <div className={this.props.containerClass ? this.props.containerClass : "blog-post"}>
                            {
                                this.props.hideHeader ? (
                                    <div className="blog-post-header"> 
                                        <div className="blog-post-content-container">
                                            <div className="blog-post-content">
                                                {parse(this.props.post.wysiwyg || '')}
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="blog-post-header"> 
                                        <div className="img">
                                            <img 
                                                src={
                                                    this.props.post &&
                                                    this.props.post.header_image &&
                                                    this.props.post.header_image !== '' ? 
                                                    this.props.post.header_image : header} 
                                                alt="header" 
                                            />
                                        </div>
                                        <div className="blog-post-content-container">
                                            <div className="content">
                                                <div className="tag-date-info">
                                                    {
                                                        this.props.post &&
                                                        this.props.post.tag &&
                                                        this.props.post.tag != '' ? (
                                                            <div className="item">
                                                                <span className="badge">{this.props.post.tag}</span>
                                                            </div>
                                                        ) : ''
                                                    }
                                                    
                                                    <div className="item">
                                                        <h6>{ this.props.post.createdAt ? 
                                                        new Date(this.props.post.createdAt).toLocaleDateString('en-uk', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }) : ''}</h6>
                                                    </div>
                                                </div>
                                                <h2>{this.props.post.title || ''}</h2>
                                                <p>{this.props.post.summary || ''}</p>
                                            </div>
                                            <div className="blog-post-content">
                                                {parse(this.props.post.wysiwyg || '')}
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                            
                        </div>
                    ) : (
                        this.props.noPostsComponent ? 
                        this.props.noPostsComponent : ''
                    )
                }
            </div>
        );
    }
}

export default BlogPost;
