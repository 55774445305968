import React, { Component } from 'react'
import { EditorState, convertToRaw, ContentState } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import './WYSIWUG.scss'

class WYSIWUG_Editor extends Component {
  constructor(props) {
    super(props)
    this.state = {
      editorState: EditorState.createEmpty(),
      uploadedImages: []
    }
    this.props.input.onChange(draftToHtml(convertToRaw(this.state.editorState.getCurrentContent())))
    this.imageUpload = this.imageUpload.bind(this)
  }

  componentDidMount() {
    console.log(this.props.clearForm)
    if (this.props.clearForm) {
      const contentBlock = htmlToDraft(this.props.post.wysiwyg || '')

      console.log(this.props)
      console.log(contentBlock)

      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)
        const editorState = EditorState.createWithContent(contentState)

        console.log(editorState)
        this.setState({ editorState: editorState })
      }
    }
  }

  async imageUpload(file) {
    let form = new FormData()
    form.append('blog_image', file)

    let files = await this.props.uploadImage(form)

    return new Promise((resolve, reject) => {
      resolve({ data: { link: files.files[0].url } })
    })
  }

  onEditorStateChange = (editorState) => {
    const {
      input: { onChange, value }
    } = this.props

    const newValue = draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()))

    console.log(`Value :: ${value}`)
    console.log(`NewValue :: ${newValue}`)
    if (value !== newValue) {
      onChange(newValue)
    }

    this.setState({
      editorState
    })
  }

  render() {
    const { editorState } = this.state

    return (
      <div className='WYSIWUG_Editor'>
        <Editor
          editorState={editorState}
          wrapperClassName='wrapper-class'
          editorClassName='editor-class'
          toolbarClassName='toolbar-class'
          onEditorStateChange={this.onEditorStateChange}
          toolbar={{
            image: {
              previewImage: true,
              uploadEnabled: true,
              uploadCallback: this.imageUpload
            }
          }}
        />
      </div>
    )
  }
}

export default WYSIWUG_Editor
