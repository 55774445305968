import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { dgInput, dgSelect, dgUpload, dgTextArea, dgInputSelect, required } from 'dg-forms'
import WYSIWUG_Editor from '../wysiwug/WYSIWUG_Editor'
import './blogEditor.scss'
import 'dg-forms/dist/index.css'

let Blog_Form = (props) => {
  const {
    handleSubmit,
    submitting,
    clearForm,
    post,
    blog, // post state, where as 'post' is currentPost
    header_image,
    uploadImage,
    tags,
    headerImageAccept
  } = props

  const renderTags = []
  if (tags && tags.length > 0) {
    renderTags = tags.map((value, index) => {
      ;<option key={`redux_blogs_tags_${index}`} value={value}>
        {value}
      </option>
    })
  }

  return (
    <form onSubmit={handleSubmit} className='BlogForm'>
      <div className='container-row'>
        <Field
          disabled={blog.isPostLoading}
          name='title'
          type='text'
          component={dgInput}
          containerClass='field-group title'
          placeholder='My New Post'
          label='Post Title'
          labelClass='required'
          validate={[required]}
        />
        <Field
          disabled={blog.isPostLoading}
          labelClass='required'
          label='Status'
          name='status'
          containerClass='field-group status'
          component={dgSelect}
          validate={[required]}
          defaultValue={'Draft'}
        >
          <option value=''>Please Select</option>
          <option value='Draft'>Draft</option>
          <option value='Published'>Published</option>
        </Field>
      </div>
      <div className='container-row'>
        {header_image ? (
          <div className='image-preview'>
            <img className='img' src={header_image} alt='header img preview' />
          </div>
        ) : (
          ''
        )}
        <Field
          label='Article Image'
          labelClass=''
          disabled={blog.isPostLoading}
          multiple={false}
          accept={headerImageAccept || '.png, .jpg, .jpeg'}
          name='header_image'
          component={dgUpload}
          validate={[]}
        />
        <Field
          name='tag'
          type='text'
          component={dgInputSelect}
          containerClass='field-group tag'
          placeholder='Post Tag'
          disabled={blog.isPostLoading}
          label='Post Tag (default to General)'
          labelClass='required'
          validate={[required]}
          defaultValue={``}
        >
          <option value=''>Please Select</option>
          <option value='General'>General</option>
          {renderTags}
        </Field>
      </div>
      <div className='container-row'>
        <Field
          name='summary'
          component={dgTextArea}
          label='Article Summary'
          disabled={blog.isPostLoading}
          labelClass='required'
          validate={[required]}
        />
      </div>
      <div className='container-row'>
        <div className='form-group'>
          <button type='submit' className='redux-blogs-btn-primary' disabled={blog.isPostLoading}>
            Save
          </button>
        </div>
      </div>
      <div>
        <Field
          name='wysiwyg'
          uploadImage={uploadImage}
          component={WYSIWUG_Editor}
          clearForm={clearForm}
          post={post}
        />
      </div>
    </form>
  )
}

Blog_Form = reduxForm({
  form: 'Blog_Form'
})(Blog_Form)

export const BlogForm = Blog_Form
