import React, { Component } from 'react'
import './pagination.scss'

// Assets
import prev from '../assets/left-carrot.svg'
import next from '../assets/right-carrot.svg'

class Pagination extends Component {
  paginate(page) {
    console.log('paginate');
    let page_size = this.props.post.page_size || 6
    this.props.fetchPosts(page_size, page, this.props.filter)

    if (this.props.scrollRef) {
      window.scrollTo({
        behavior: 'smooth',
        top: this.props.scrollRef.current.offsetTop
      })
    }
  }

  render() {
    return (
      <div
        style={this.props.paginationContainerStyle || {}}
        className={
          this.props.paginationContainerClass
            ? `pagination-container ${this.props.paginationContainerClass}`
            : 'pagination-container'
        }
      >
        {this.props.total_pages > 1 ? (
          <div
            style={this.props.paginationStyle || {}}
            className={
              this.props.paginationClass
                ? `pagination ${this.props.paginationClass}`
                : 'pagination'
            }
          >
            <div className='item'>
              <button
                type='button'
                className='indicators'
                onClick={() => {
                  this.paginate(this.props.page - 1)
                }}
                disabled={this.props.page <= 0}
              >
                <img
                  src={prev}
                  alt='previous page'
                  style={{ marginRight: '3px' }}
                />
              </button>
            </div>

            {this.props.page > 0 ? (
              <div className='item'>
                <p>{this.props.page + 1 - 1}</p>
              </div>
            ) : (
              <div className='item'></div>
            )}

            <div className='item active'>
              <p>{this.props.page + 1}</p>
            </div>

            {this.props.page < this.props.total_pages - 1 ? (
              <div className='item'>
                <p>{this.props.page + 1 + 1}</p>
              </div>
            ) : (
              <div className='item'></div>
            )}

            <div className='item'>
              <button
                type='button'
                className='indicators'
                onClick={() => {
                  this.paginate(this.props.page + 1)
                }}
                disabled={this.props.page >= this.props.total_pages - 1}
              >
                <img
                  src={next}
                  alt='previous page'
                  style={{ marginLeft: '3px' }}
                />
              </button>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    )
  }
}

export default Pagination
