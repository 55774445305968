import React, { Component } from 'react';
import './blogEditor.scss';

// Actions
// import { createNewPost, setClearPostForm } from '../../../actions/post.action';
import { getAllTags } from '../../actions/post.actions';

// Components
import { BlogForm } from './blogForm.component';

class EditPostPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      time: 0,
      start: 0
    }

    this.startTimer = this.startTimer.bind(this)
  }

    componentDidMount() {
        getAllTags();
        this.startTimer();
    }
    
  componentDidUpdate() {
    if (this.state.time === 100) {
      this.focus()
    }
  }

  focus() {
    document.getElementsByClassName('public-DraftEditor-content')[0].focus()
    console.log('focused')
  }

  startTimer() {
    this.timer = setInterval(
      () =>
        this.setState({
          time: this.state.time + 10
        }),
      10
    )
  }
    render() {
        return (
            <div className='blogEditor'>
                {
                    this.props.post &&
                    this.props.post.title !== '' ? (
                        <BlogForm 
                            onSubmit={this.props.onSubmit}
                            initialValues={this.props.post}
                            clearForm={true}
                            uploadImage={this.props.uploadImage}
                            post={this.props.post}
                            blog={this.props.blog}
                            headerImageAccept={this.props.headerImageAccept}
                            header_image={this.props.post?.header_image}
                            tags={this.props.post?.tags}
                            
                        />
                    ) : (
                        this.props.noPostComponent ? this.props.noPostComponent : (
                            <h1>No Post Selected</h1>
                        )
                    )
                }
            </div>
        );
    }
}

export default EditPostPage;
